import { scroll, timeline } from "motion";

export default function masthead() {
	const isLivePreview = document
		.querySelector("body")
		.classList.contains("live-preview");
	return {
		init() {
			if (window.location.hash === "") {
				this.enterAnimation();
			} else {
				const el = document.querySelector(window.location.hash);
				el.scrollIntoView({ behavior: "smooth" });
				this.scollAnimation();
			}
		},
		enterAnimation() {
			this.$root.style.opacity = "0";
			this.$refs.logoPath.style.opacity = "0";
			this.$refs.logoType.style.opacity = "0";
			if (!isLivePreview) {
				document.querySelector("body").classList.add("overflow-hidden");
				document.querySelector("body").classList.add("h-screen");
			}
			const enterSequence = [
				[
					this.$root,
					{
						opacity: ["0", "1"],
					},
					{ duration: 2 },
				],
				[
					this.$refs.logoPath,
					{
						opacity: ["0", "1"],
					},
					{
						duration: 1,
						at: "<",
					},
				],
				[
					this.$refs.logoPath,
					{
						strokeDasharray: ["0,1", "1,1"],
					},
					{ at: "-0.5" },
				],
				[
					this.$refs.logoType,
					{
						opacity: ["0", "1"],
					},
				],
			];
			timeline(enterSequence, {
				defaultOptions: {
					duration: 1,
					easing: "ease-in-out",
				},
			}).finished.then(() => {
				if (!isLivePreview) {
					document
						.querySelector("body")
						.classList.remove("overflow-hidden");
					document.querySelector("body").classList.remove("h-screen");
				}
				this.scollAnimation();
			});
		},
		scollAnimation() {
			const mainNav = document.getElementById("mainNav") || null;

			const scrollSequence = [
				[
					this.$refs.strapline,
					{
						opacity: ["0", "1"],
					},
				],
				[
					this.$refs.logoBlock,
					{
						opacity: ["1", "0"],
					},
				],
				[
					this.$refs.imageSlide1,
					{
						opacity: ["1", "0"],
					},
					{ at: "<" },
				],
				[
					this.$refs.textSlide1,
					{
						opacity: ["0", "1"],
					},
				],
				[
					mainNav,
					{
						opacity: ["0", "1"],
					},
					{ at: "<" },
				],
				[
					this.$refs.textSlide1,
					{
						opacity: ["1", "0"],
					},
				],
				[
					this.$refs.imageSlide2,
					{
						opacity: ["1", "0"],
					},
					{ at: "<" },
				],
				[
					this.$refs.textSlide2,
					{
						opacity: ["0", "1"],
					},
				],
				[
					this.$refs.textSlide2,
					{
						y: "-50vh",
					},
				],
				[
					this.$root,
					{
						opacity: ["1", "0"],
					},
					{ at: "<" },
				],
			];

			const scrollSequenceWOMainNav = [
				[
					this.$refs.strapline,
					{
						opacity: ["0", "1"],
					},
				],
				[
					this.$refs.logoBlock,
					{
						opacity: ["1", "0"],
					},
				],
				[
					this.$refs.imageSlide1,
					{
						opacity: ["1", "0"],
					},
					{ at: "<" },
				],
				[
					this.$refs.textSlide1,
					{
						opacity: ["0", "1"],
					},
				],
				[
					this.$refs.textSlide1,
					{
						opacity: ["1", "0"],
					},
				],
				[
					this.$refs.imageSlide2,
					{
						opacity: ["1", "0"],
					},
					{ at: "<" },
				],
				[
					this.$refs.textSlide2,
					{
						opacity: ["0", "1"],
					},
				],
				[
					this.$refs.textSlide2,
					{
						y: "-50vh",
					},
				],
				[
					this.$root,
					{
						opacity: ["1", "0"],
					},
					{ at: "<" },
				],
			];

			scroll(
				timeline(mainNav ? scrollSequence : scrollSequenceWOMainNav),
				{
					target: this.$root,
					offset: ["start start", "end start"],
				}
			);
		},
	};
}
